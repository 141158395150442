import { format } from 'date-fns';
import LanguageDetect from 'languagedetect';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  HStack,
  Heading,
  Hide,
  Spacer,
  Stack,
  Text,
  useMediaQuery
} from '@chakra-ui/react';
import CoverMedia from 'components/CoverMedia';
import Description from 'components/Description';
import MembersButton from 'components/MembersButton';
import TranslateButton from 'components/TranslateButton';
import Vote from 'components/Vote';
import GridAttachmentList from 'features/attachment/GridAttachmentList';
import CommentSection from 'features/comment/CommentSection';
import { Plans, hasPlan } from 'features/hive/hiveUtils';
import api from 'utils/api';
import CardStepAccordions from './CardStepAccordions';
import CardActionMenu from './CardActionMenu';
import CardStep from './CardStep';
import ContributorFeedback from './ContributorFeedback';

const detector = new LanguageDetect();

const CardBody = ({
  authenticated,
  card,
  code,
  updateCard,
  canEdit,
  isAdminOrCollectionManager,
  isAdminOrAssigneeOrCollectionManager,
  onChangeContributors,
  onClickVote,
  onClickDelete,
  onClickEdit,
  onClickViewReport,
  onClickChangeCollection,
  updateCardMutation
}) => {
  const { t } = useTranslation();
  const { slug } = useParams();

  const [isMobile] = useMediaQuery('(max-width: 62em)');
  const [translations, setTranslations] = useState(null);
  const [showTranslated, setShowTranslated] = useState(false);

  const languages = detector.detect(`${card?.title} ${card?.description}`, 2);

  const handleOnClickTranslate = async () => {
    if (!translations) {
      try {
        const { data } = await api.post('/services/translate', {
          texts: [card.title, card.description]
        });
        setTranslations(data.translations);
      } catch (e) {
        console.log(e);
      }
    }
    setShowTranslated(!showTranslated);
  };

  const handleOnChangePrivate = async value => {
    await updateCardMutation.mutateAsync({ private: value, hive: slug });
  };

  return (
    <Stack spacing={8}>
      <CoverMedia
        position="relative"
        object={card}
        height={['300px', null, '200px']}
        hideIfEmpty
      />
      <Stack>
        <HStack spacing={[2, 4, 8]} justifyContent="space-between">
          <HStack spacing={2} width="full" alignItems="flex-start">
            <MembersButton
              showCount={isMobile}
              code={code}
              max={3}
              members={card.contributors}
              onChangeMembers={onChangeContributors}
              modalTitle={t('common.contributors')}
              canEdit={canEdit}
              canRemoveSelf={false}
            />
            <Hide below="lg">
              <Stack spacing={0}>
                {card.contributors.length > 0 && (
                  <Text fontSize="sm" fontWeight="bold">
                    {card.contributors.length === 1
                      ? card.contributors[0].first_name
                      : `${card.contributors.length} ${t(
                          'common.contributors'
                        )}`}
                  </Text>
                )}
                <Text fontSize="xs">
                  {format(
                    new Date(card.created_at),
                    isMobile ? 'dd MMM yyyy' : 'dd MMM yyyy, HH:mm'
                  )}
                </Text>
              </Stack>
            </Hide>
            <Spacer />
            <HStack justifyContent="flex-end">
              {card.collection?.workflow?.use_card_votes ? (
                <Vote
                  voted={card.voted}
                  downVotes={card.metrics.downvotes}
                  upVotes={card.metrics.upvotes}
                  onClickVote={vote => {
                    onClickVote(vote);
                  }}
                />
              ) : null}
              {canEdit ? (
                <Box>
                  <CardActionMenu
                    card={card}
                    collection={card.collection}
                    onClickEdit={onClickEdit}
                    onClickDelete={onClickDelete}
                    onClickViewReport={onClickViewReport}
                    onClickChangeCollection={onClickChangeCollection}
                    onClickChangePrivate={handleOnChangePrivate}
                    isAdminOrCollectionManager={isAdminOrCollectionManager}
                  />
                </Box>
              ) : null}
            </HStack>
          </HStack>
        </HStack>
      </Stack>

      <Stack spacing={6}>
        <Heading fontSize="lg">
          {translations && showTranslated ? translations[0] : card.title}
        </Heading>
        {(card.collection.workflow
          ? card.collection.workflow.use_card_description
          : true) && (
          <Description>
            {translations && showTranslated
              ? translations[1]
              : card.description}
          </Description>
        )}
        {languages &&
          languages.length > 0 &&
          languages[0][0] !== 'english' &&
          languages[0][1] > 0.2 && (
            <TranslateButton
              alignSelf="flex-start"
              showTranslated={showTranslated}
              onClick={handleOnClickTranslate}
            />
          )}
        <GridAttachmentList
          code={code}
          modelConfig={{
            singular: 'card',
            plural: 'cards',
            modelId: card.id
          }}
          canEdit={canEdit}
          gridColumns={[2, 3, 4]}
        />

        {authenticated &&
          (isAdminOrAssigneeOrCollectionManager ? (
            <>
              <CardStep
                code={code}
                card={card}
                filter={{ step__isnull: true }}
                noEmptyState
              />
              <CardStepAccordions card={card} code={code} />
            </>
          ) : (
            <ContributorFeedback card={card} />
          ))}
        <CommentSection
          commentCount={card.metrics.comments}
          cardId={card.id}
          updateCard={updateCard}
          code={code}
          isAdminOrAssigneeOrCollectionManager={
            isAdminOrAssigneeOrCollectionManager
          }
        />
        {hasPlan(card.collection.hive?.plan, Plans.Freemium) && (
          <>
            <Divider />
            <Text>
              <Trans
                t={t}
                i18nKey="card.powered_by_hives"
                components={[
                  <Text />,
                  <Button
                    variant="link"
                    colorScheme="blue"
                    as="a"
                    href="https://hives.co"
                    target="_blank"
                  />,
                  <Text />,
                  <Button
                    variant="link"
                    colorScheme="blue"
                    as="a"
                    href="https://hives.co"
                    target="_blank"
                  />
                ]}
              />
            </Text>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default CardBody;
